<template>
  <content-wrapper :title="labels.LBL_SYSTEM_ALBUM" back>
    <template slot="actions">
      <el-button size="small" type="success" @click="submitForm('albumForm')">
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_UPDATE }}
      </el-button>
    </template>

    <el-form
      v-loading="loading"
      ref="albumForm"
      :model="album"
      :rules="rules"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item prop="name" :label="labels.LBL_NAME">
            <el-input
              v-model="album.name"
              :placeholder="labels.PH_NAME"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="labels.LBL_PUBLISH_ALBUM">
            <el-switch v-model="album.is_published"></el-switch>
          </el-form-item>
        </el-col>

        <el-col :span="16">
          <el-form-item prop="description" :label="labels.LBL_DESCRIPTION">
            <el-input
              v-model="album.description"
              type="textarea"
              :rows="4"
              :placeholder="labels.PH_DESCRIPTION"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="labels.LBL_IMAGES">
            <multi-image-editor :images="images" @onImageAdd="handleAddImage" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item :label="labels.LBL_VIDEOS">
            <multi-video-editor :videos="videos" @onVideoAdd="handleAddVideo" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </content-wrapper>
</template>

<script>
  import { mapState } from "vuex";
  import { labels, rules } from "@/common";
  import Album from "@/models/album";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";
  import MultiImageEditor from "@/components/project-albums/MultiImageEditor";
  import MultiVideoEditor from "@/components/project-albums/MultiVideoEditor";

  export default {
    name: "EditProject",

    components: {
      ContentWrapper,
      MultiImageEditor,
      MultiVideoEditor,
    },

    props: {
      albumId: {
        type: [Number, String],
      },
    },

    data() {
      return {
        labels,
        rules,
        album: new Album(),
        images: [],
        videos: [],
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        albumDetail: (state) => state.album.albumDetail,
        // systemTypes: (state) => state.album.systemTypes,
      }),

      // systemUnit() {
      //   return this.systemTypes.find((s) => s.id === this.album.system_type_id)
      //     .unit;
      // },
    },

    watch: {
      albumId: {
        immediate: true,
        handler(nv) {
          this.loadAlbumDetail(nv);
        },
      },

      albumDetail: {
        handler(nv) {
          this.setAlbumDetail(nv);
        },
      },
    },

    created() {
      // this.loadSystemType();
    },

    methods: {
      // loadSystemType() {
      //   this.$store.dispatch("album/getSystemTypes");
      // },

      loadAlbumDetail(id) {
        this.$store.dispatch("album/getAlbumDetail", id);
      },

      setAlbumDetail(detail) {
        const {
          id,
          name,
          description,
          is_published: isPublished,
          album_medias: albumMedias,
        } = detail;
        this.album.id = id;
        this.album.name = name;
        this.album.description = description;
        this.album.is_published = !!isPublished;

        const { img, vid } = albumMedias.reduce(
          (acc, am) => {
            if (am.url) acc.vid = [...acc.vid, am];
            else acc.img = [...acc.img, am];
            return acc;
          },
          { img: [], vid: [] }
        );

        this.images = img;
        this.videos = vid;
      },

      handleAddImage(images) {
        this.images = images;
      },

      handleAddVideo(videos) {
        this.videos = videos;
      },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const allMedias = [...this.images, ...this.videos];
            const {
              album_medias: albumMedias,
              update_album_medias: updatedAlbumMedias,
            } = allMedias.reduce(
              (acc, m) => {
                if ("created_at" in m) {
                  acc.update_album_medias = [
                    ...acc.update_album_medias,
                    {
                      ...m,
                      poster: m.poster instanceof File ? m.poster : null,
                    },
                  ];
                } else {
                  acc.album_medias = [...acc.album_medias, m];
                }
                return acc;
              },
              { album_medias: [], update_album_medias: [] }
            );
            this.album.album_medias = albumMedias;
            this.album.update_album_medias = updatedAlbumMedias;

            if (
              !(
                this.album.album_medias.length > 0 ||
                this.album.update_album_medias.length > 0
              )
            ) {
              this.$message.error("Please add images or video in the album");
            } else {
              this.$store.dispatch("album/updateAlbum", this.album);
            }
          }
        });
      },
    },
  };
</script>

<style></style>
